import React from "react"
import Divider from "../../../components.refactored/standard/Home/icons/divider-dark.svg"
import {
  summary,
  summary__button,
  summary__error,
  summary__divider,
  summary__divider__mirror,
} from "./styles/summary.module.scss"
import { AnimatedLink } from "@components/shared"

const PurchaseFailed = ({ orderId, msg }) => {
  return (
    <div className={summary}>
      <img src={Divider} className={summary__divider__mirror}></img>
      <div className={summary__error}>
        <h1>Ups</h1>
        <h1>Coś poszło nie tak z płatnością.</h1>
      </div>
      <img src={Divider} className={summary__divider}></img>
      <AnimatedLink className={summary__button} to="/">
        Powrót do sklepu
      </AnimatedLink>
    </div>
  )
}
export default PurchaseFailed
